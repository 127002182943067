import React from "react"

// Libraries
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import LinkWithArrow from "components/link-with-arrow"
import HrefWithArrow from "components/href-with-arrow"

const StyledPostItem = styled.section`

	.list_row {
		display: none;
		width: 95%;
		flex-wrap: wrap;
		margin: 0 auto;
		justify-content: center;
		align-items: center;
		padding: 10px 0 0 0;

		  ${breakpoint.small`
				flex-wrap: nowrap;
				column-gap: 10px;
				columns: 2;
				padding: 10px 0 10px 0;
		  `}

		  ${breakpoint.medium`
				padding: 0 0 40px 0;
		  `}

			&.visible {
				display: flex;
			}
			
		.image_column { 
			width: 100%;
			margin: 17px auto 0;
			align-self: flex-start;
			max-width: 600px;
			
		  ${breakpoint.small`
			margin: 17px 10px 0;
			max-width: 400px;
		  `}
			
		  ${breakpoint.medium`
			max-width: 450px;
		  `}
		}
		
		 .text_column { 
			width: 95%;
			border-left: 0;
			margin: 30px 15px 15px 15px;
			text-align: center;
			
		  ${breakpoint.small`
				margin: 15px 0 10px 10px;
				align-self: start;
				padding-left: 0;
				text-align: left;
		  `}
		}

		h2 {
			font-size: 26px;
			margin: 0 0 10px 0;
			
			  ${breakpoint.small`
				font-size: 29px;
			  `}
		}
		

		p {
			padding: 0;
			font-size: 17px;
			line-height: 1.4em;
		
			  ${breakpoint.small`
					font-size: 19px;
					padding: 0px 10px 0 0;
					line-height: 1.5em;
			  `}
			}
			.postDate {
				font-size: 18px;
				line-height: 1.3em;
				margin: 0 0 5px 0;
				font-weight: 700;
			}
	
			.featureDate {
				font-size: 17px;
				line-height: 1.3em;
				margin: 12px 0 0 0;
				font-weight: 700;
			}

		.links {
			margin-top: 5px;
		}
				
		b {
			font-weight: 600;
		}
		
		a {
			color: ${colors.purpleDark};

			&:hover {
			  color: ${colors.orange};
			}
		}	
	
		.link {
			font-size: 16px;
			margin: 0;
			color: ${colors.orange};

			${breakpoint.small`
				margin: 15px 0 0 0;
				text-align: left;
			`}

			  &:hover {
				color: ${colors.orange};
			  }
		}

		.externalButton { 
			margin-top: 10px;
			color: ${colors.charcoal};

			&:hover {
				color: ${colors.white};
			  }
		}


		.alignLinkCenter {
			display: flex;
			justify-content: center;
		
			${breakpoint.small`
				justify-content: flex-start;
			`}
		}

		.publicationImage {
			border: 1px solid ${colors.gray};
			height: 60%;	

			${breakpoint.small`
				height: 80%;	
			`}

			${breakpoint.medium`
				height: 100%;	
			`}
		}
		
		.nowrap {
			white-space: nowrap;
		}
		
	}
			
		  .miscData {
			display: none;
		  }
	`


const PostList = props => {
	return (
		<StyledPostItem {...props} id={props.slug} itemScope itemType="http://schema.org/Article">
		<div className="list_row visible" data-filter={props.year} data-filter-type="news">
				<div className="image_column">
					{props.image ? (
						<a href={props.resourceType === "webinarFeature" | props.featured ? props.featuredUrl : props.link} 
						style={{ display: "inline-block" }} tabIndex="-1" 
						target={props.resourceType === "webinarFeature" | props.featured | props.newWindow==="_blank" ? "_blank" : null} 
						rel="noopener noreferrer"
						>
							<GatsbyImage
								image={getImage(props.image)}
								width={400}
								alt={props.title}
								title={props.title}
								style={{
									backgroundPosition: "center center",
								}}
								className="publicationImage"
							/>
						</a>
					) : null}
				</div>
				<div className="text_column">
					{props.resourceType === "post" ? (
						<p className="postDate">{props.date}</p>
					) :
						null
					}
					{props.product ? (<p className="postDate">{props.product}</p>) : null}
					{props.resourceType === "webinar" ? (<p className="postDate">{props.presenter}  &bull; <span className="nowrap">{props.date}</span></p>) : null}
					{props.resourceType === "webinarFeature" ? (<p className="postDate">{props.presenter}</p>) : null}

					{props.resourceType === "webinarFeature" | props.featured  ?
						<h2 itemProp="headline"><a href={props.featuredUrl} style={{ display: "inline-block" }} target="_blank" rel="noopener noreferrer">{props.title}</a></h2>
					:
						<h2 itemProp="headline"><a href={props.link} style={{ display: "inline-block" }} target={props.newWindow} rel={props.relRef}>{props.title}</a></h2>
					}
					{props.resourceType === "post" ? (
						<div
							itemProp="description"
							className="excerpt"
							dangerouslySetInnerHTML={{ __html: props.excerpt }}
						>
						</div>
					) : (
						<p itemProp="description">{props.description}</p>
					)}
					{props.resourceType === "webinarFeature" ? (<p className="featureDate">{props.featuredDate} &bull; {props.featuredTime} &bull; Cost: {props.featuredCost}</p>) : null}

					<div className="alignLinkCenter">
						<p className="links">
							{props.resourceType === "webinarFeature" ?
								(
									<HrefWithArrow
										href={props.featuredUrl}
										className="link"
										text={props.linkText}
										color={colors.orange}
										tabIndex="-1"
									/>
								)
								:
								(
									<LinkWithArrow
										to={props.link}
										className="link"
										text={props.linkText}
										color={colors.orange}
										tabIndex="-1"
									/>
								)
							}
						</p>
					</div>
				</div>
			</div>
			<div className="miscData">
				<meta itemProp="url" content={process.env.GATSBY_VISBY_URL + props.link} />
				{props.publicURL ? (
					<meta itemProp="og:image" content={process.env.GATSBY_VISBY_URL + props.publicURL} />
				) :
					<meta itemProp="og:image" content={process.env.GATSBY_VISBY_DEFAULT_IMAGE} />
				}
				{props.publicURL ? (
					<meta itemProp="image" content={process.env.GATSBY_VISBY_URL + props.publicURL} />
				) :
					<meta itemProp="image" content={process.env.GATSBY_VISBY_DEFAULT_IMAGE} />
				}
				<meta itemProp="datePublished" content={props.dateGmt} />
				<meta itemProp="dateModified" content={props.modifiedGmt} />
				<div itemProp="publisher" itemScope itemType="https://schema.org/Organization">
					<meta itemProp="name" content="Visby Medical" />
					<div itemProp="logo" itemScope itemType="https://schema.org/ImageObject">
						<meta itemProp="url" content={process.env.GATSBY_VISBY_LOGO} />
					</div>
				</div>
				<div itemProp="author" itemScope itemType="https://schema.org/Organization">
					<meta itemProp="name" content="Visby Medical" />
					<meta itemProp="url" content={process.env.GATSBY_VISBY_URL} />
				</div>
			</div>
		</StyledPostItem>
	)
}
export default PostList 