import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import PostItem from "components/resource-list"

const StyledContainer = styled(Container)`
  max-width: 1200px; 
  padding: 0;
  margin: 0 auto;

  h3 {
	font-size: 24px;
	text-align: center;
	margin: 0;
	padding: 0;

	${breakpoint.small`
		font-size: 22px;
		margin-left: 40px;
		text-align: left;
	`}
}
`

const StyledWebinars = styled.section`
	padding: 50px 0;
	background: ${colors.purpleLightBgNew};
	max-width: 1200px; 
	margin: 0 auto;

	${breakpoint.medium`
		padding: 50px 0 20px;
	`}
`

const Webinars = props => {
	const data = useStaticQuery(graphql`
    query {
        allWpWebinar (filter: {webinars: {featured: {eq: true}}}, limit: 1) {
        nodes {
          id
          title
		  slug
		  dateGmt
		  modifiedGmt
		webinars {
			existingSlug
			product
			description
			presenter
			presentationDate
			featured
			featuredDate
			featuredTime
			featuredCost
			featuredUrl
            photo {
              localFile {
				publicURL
                childImageSharp {
					gatsbyImageData(width: 600)
                  }
                }
              }
            }
          }
        }
      }
  `)
  return (
		<StyledWebinars data-aos="fade-up">
		{data.allWpWebinar.nodes.map((post) => (
		<StyledContainer key={post.id}>
				<div className="fullList">
					<h3 data-aos="fade-up">UPCOMING WEBINAR</h3>
						<PostItem
							resourceType="webinarFeature"
							key={post.id}
							link={post.webinars.existingSlug}
							title={post.title}
							description={post.webinars.description}
							presenter={post.webinars.presenter}
							image={  
								post.webinars.photo.localFile
									? post.webinars.photo.localFile
									: false
							}
							publicURL={
								post.webinars.photo.localFile.publicURL
									? post.webinars.photo.localFile.publicURL
									: false
							}
							date={post.webinars.presentationDate}
							dateGmt={post.dateGmt}
							modifiedGmt={post.modifiedGmt}
							featured={post.webinars.featured}
							featuredDate={post.webinars.featuredDate}
							featuredTime={post.webinars.featuredTime}
							featuredCost={post.webinars.featuredCost}
							featuredUrl={post.webinars.featuredUrl}
							linkText="Register"
						/>
				</div>
			</StyledContainer>
		) ) }
		</StyledWebinars>
	)
}

export default Webinars