import React from "react"

// Libraries
import { Link } from "gatsby"
import styled from "styled-components"

const StyledArrow = styled.span`
  width: 24px;
  height: 1px;
  position: relative;
  margin-right: 10px;
  background-color: ${props => props.color};
  transition: all 0.3s;

  &::before {
    content: "";
    width: 1px;
    height: 8px;
    position: absolute;
    top: -7px;
    right: 3px;
    background-color: ${props => props.color};
    transform: rotate(-45deg);
  }

  &::after {
    content: "";
    width: 1px;
    height: 8px;
    position: absolute;
    top: 0;
    right: 3px;
    background-color: ${props => props.color};
    transform: rotate(-135deg);
  }
`

const StyledLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  color: ${props => props.color};
  font-size: 14px;
  font-weight: 700;
  line-height: 1.8em;
  white-space: nowrap;

  &::before {
    content: "";
    width: 1px;
    height: 10px;
    position: absolute;
    top: 3px;
    left: 0;
    background-color: ${props => props.color};
    transition: all 0.3s;
  }

  &:hover {
    &::before {
      height: 0;
      transform: translateY(10px);
    }

    ${StyledArrow} {
      width: 34px;
    }
  }
`

const LinkWithArrow = props => {
  return (
    <StyledLink
      className={props.className}
      color= {props.color}
      to={props.to}
      tabIndex={props.tabIndex}
      onMouseEnter={props.onMouseEnter ? () => props.onMouseEnter() : null}
      onMouseLeave={props.onMouseLeave ? () => props.onMouseLeave() : null}
    >
      <StyledArrow color={props.color} />
      {props.text}
    </StyledLink>
  )
}
export default LinkWithArrow
